<template>
  <v-container
    id="error-view"
    class="fill-height text-center pa-0 pa-md-3"
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12" sm="9" md="8" lg="6" xl="4">
        <template>
          <v-card
            color="primary"
            dark
            class="pa-0 pa-md-3"
            :class="
              $vuetify.breakpoint.smAndDown ? 'elevation-0' : 'elevation-24'
            "
          >
            <v-card-title class="py-9">
              <v-icon x-large>
                mdi-lastpass
              </v-icon>
              <div class="pl-3 text-h3">
                {{ $t("sifreUnuttum") }}
              </div>
            </v-card-title>

            <v-card-text>
              <form @submit.prevent="submit">
                <v-text-field
                  ref="email"
                  v-model="email"
                  :prepend-icon="
                    $vuetify.breakpoint.smAndDown ? '' : 'mdi-login'
                  "
                  type="email"
                  :label="$t('eposta')"
                />
              </form>
            </v-card-text>
            <v-card-actions class="pb-9">
              <v-btn small color="white" plain text to="/login">
                {{ $t("geri") }}
              </v-btn>

              <v-spacer />
              <v-btn
                large
                color="accent"
                depressed
                class="px-8 px-md-16"
                @click="reset()"
              >
                {{ $t("sifreDegistir") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      elevation="24"
      vertical
      max-width="600"
      :color="errorColor"
      timeout="6000"
      absolute
      right
      top
    >
      {{ errText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          {{ $t("kapat") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { giris, veri } from "./../db";
export default {
  name: "ResetView",
  data: () => ({
    email: "",
    timeout: 6000,
    snackbar: false,
    errText: "",
    errorColor: ""
  }),
  methods: {
    reset() {
      giris
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.errorColor = "primary";
          this.snackbar = true;
          this.errText = this.$t("ePostaOnay");

          veri.ref("userStatus/yeni").set(false);
        })
        .catch(error => {
          this.errorColor = "error";
          this.snackbar = true;
          this.errText = error.message;
        });
    }
  }
};
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-background-clip: text;
}

.v-application--is-ltr .v-input__prepend-outer {
  margin-right: 40px;
}
</style>
